import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { graphql, PageProps } from 'gatsby';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { HydrationProvider, Client } from 'react-hydration-provider';
import { StrapiFooterCtaComponent } from '../components/StrapiComponents/StrapiFooterCta';
import ComponentRenderer from '../components/ComponentRenderer/ComponentRenderer';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../components/LayoutWrapper';
import SEO from '../components/SEO/SEO';
import { Scalars, StrapiHomepageQuery } from '../../graphql.schema';
import { HomepageHero } from '../components/HomepageHero/HomepageHero';
import { GlobalPageContext } from '../types';
import { v4tov3base } from '../lib/mapV4toV3';

import * as styles from './page.module.scss';

type PageContextType = GlobalPageContext & {
  id: Scalars['ID'];
};

const Homepage: React.FC<PageProps<StrapiHomepageQuery, PageContextType>> = ({ data, pageContext }) => {
  const { homepage, courses } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });

  if (!websiteLocale || !homepage) {
    return (
      <LayoutWrapper {...pageContext}>
        <SEO title={data.strapi.homepage?.title} />
        <h1 className="title">
          <FormattedMessage id="notFoundTextHeading" defaultMessage="Page cannot be found." />
        </h1>
      </LayoutWrapper>
    );
  }
  return (
    <HydrationProvider>
      <Client>
        <LayoutWrapper {...pageContext}>
          <SEO
            title={homepage.seo?.metaTitle ?? homepage.pageSettings?.title ?? homepage.heroTitle}
            description={homepage.seo?.metaDescription ?? homepage.pageSettings?.metaDescription ?? homepage.heroBody}
            image={homepage.seo?.metaImage?.url ?? homepage.heroImage?.url}
            avoidIndexing={homepage.pageSettings?.avoidIndexing ?? false}
            canonicalURL={homepage.seo?.canonicalURL}
            keywords={homepage.seo?.keywords}
            metaSocial={homepage.seo?.metaSocial}
            structuredData={homepage.seo?.structuredData}
            alternatePages={pageContext?.availableRoutes}
            currentPage={pageContext}
          />

          <div className="container">
            <HomepageHero
              courseSlugBase={pageContext.pathPrefixes.courses}
              promotionSlugBase={pageContext.pathPrefixes.promotions}
              promotion={homepage.promotion ?? undefined}
              title={homepage.heroTitle}
              intro={homepage.heroBody}
              cta={homepage.heroCta}
              image={homepage.heroImage ?? undefined}
              courses={{
                title: homepage.coursesTitle,
                intro: homepage.coursesBody,
                cta: homepage.coursesCta ?? undefined,
                courses: compact(courses),
              }}
              websiteLocale={websiteLocale}
              heroIntro={homepage.heroSection}
              showFormInHeader={homepage?.showFormInHeader}
              inlineHubspotForm={homepage?.inlineHubspotForm}
            />
          </div>
          {homepage.components.length > 0 ? (
            <div className={classnames('container page-components', styles.pageComponents)}>
              {homepage.components?.map((component, idx) =>
                component ? (
                  <ComponentRenderer
                    component={component}
                    key={`homepage-component-${component.__typename}-${idx}`}
                    hasRTL={websiteLocale?.hasRightToLeftLanguage}
                  />
                ) : null,
              )}
            </div>
          ) : null}
          {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
        </LayoutWrapper>
      </Client>
    </HydrationProvider>
  );
};

export const query = graphql`
query StrapiHomepage($id: ID!, $websiteLocaleId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    homepage(id: $id) {
      data {
        id
        attributes {
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          title
          heroImage: hero_image {
            data {
              id
              attributes {
                alternativeText
                caption
                width
                height
                formats
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: CONSTRAINED
                      formats: [AUTO, WEBP, AVIF]
                      width: 600
                      height: 746
                      breakpoints: [750, 1080, 1366, 1920]
                      quality: 50
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
                hash
              }
            }
          }
          heroTitle: hero_title
          heroBody: hero_body
          heroCta: hero_cta {
            ...LinkComponent
          }
          heroSection: hero_section {
            hero_title
            hero_full_image {
              data {
                id
                attributes {
                  alternativeText
                  caption
                  width
                  height
                  formats
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        breakpoints: [750, 1080, 1366, 1920]
                        quality: 50
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                  hash
                }
              }
            }
            heroBodyFeature: hero_body_features {
              feature
            }
            hero_cta {
              ...LinkComponent
            }
            show_hero_section
            promotion {
              data {
                id
                attributes {
                  ...Promotion
                }
              }
            }
          }
          showFormInHeader: show_form_in_header
          inlineHubspotForm: inline_hubspot_form {
            ...InlineHubspotForm
          }
          coursesTitle: courses_title
          coursesBody: courses_body
          coursesCta: courses_cta {
            ...LinkComponent
          }
          components {
            ...PageComponents
            __typename
            ... on Strapi_ComponentPageTrustPilotWidget {
              heading
              script {
                ...HTMLComponent
              }
            }
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          promotion {
            data {
              id
              attributes {
                ...Promotion
              }
            }
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
    courses(
      filters: { website_locale: { id: { eq: $websiteLocaleId } } }
      locale: $locale
      sort: "original_date:desc"
    ) {
      data {
        id
        attributes {
          featured
          name
          short
          slug
          image {
            data {
              id
              attributes {
                alternativeText
                caption
                width
                height
                formats
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      width: 175
                      height: 224
                      breakpoints: [750, 1080, 1366, 1920]
                      quality: 50
                    )
                  }
                }
                hash
              }
            }
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(Homepage));
